<template>
  <h2>GENUARY 2024</h2>
  <div class="content">
    <div class="container">
      <GalleryItemHTML
    v-for="item in portfolioItems"
    :key="item.id"
    :imgname="item.imgname"
    :pagename="item.pagename"
    :title="item.title"
    :date="item.date"
    ></GalleryItemHTML>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import GalleryItemHTML from '../components/GalleryItemHTML.vue';
export default {
  name: "Home",
  components: {
    GalleryItemHTML
  },
  data() {
    return {
      portfolioItems: [
        {id:1,imgname:'frame_969.png',pagename:"../2024day1/index.html",title:"DAY 1 - PARTICLES"},
        {id:2,imgname:'droste.png',pagename:"../2024day3/index.html",title:"DAY 3 - DROSTE EFFECT"},
        {id:3,imgname:'armz.png',pagename:"../2024day4/index.html",title:"DAY 4 - PIXELS"},
        {id:4,imgname:'veramolnar.png',pagename:"../2024day5/index.html",title:"DAY 5 - VERA MOLNAR"},
        {id:5,imgname:'vlcsnap-2024-01-09-21h06m52s312.png',pagename:"../2024day6/index.html",title:"DAY 6 - SCREENSAVER"},
        {id:6,imgname:'vlcsnap-2024-01-09-21h18m30s535.png',pagename:"../2024day7/index.html",title:"DAY 7 - LOADING ANIMATION"},
        {id:7,imgname:'vlcsnap-2024-01-09-21h19m04s338.png',pagename:"../2024day8/index.html",title:"DAY 8 - CHAOTIC SYSTEM"},
        {id:8,imgname:'mmmkay.png',pagename:"../2024day9/index.html",title:"DAY 8 - ANNI ALBERS"},
      ]
    }
  }
};
</script>
<style scoped>
.container {
  height: 5000px;
}
</style>