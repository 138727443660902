<template>
    <div class="item">
        <img v-bind:src="require('../assets/' + imgname)">
        <a v-bind:href="pagename">
            <div class="imageoverlay imageoverlay--blur">
                <div class="overlaytitle">{{title}}</div>
                <div class="overlaydate">{{date}}</div>
            </div>
        </a>
        </div>
</template>
<style scoped>
.item > img {
    width:100%;
    border-radius:8px;
    display: block;
}
</style>
<script>
    export default {
        name: 'GalleryItemHTML',
        props: ['imgname', 'pagename','title','date'],
    }
</script>
